import { Injectable } from '@angular/core';
import { AlertController, ToastController, LoadingController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

declare var QRScanner: any;

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  public loading: HTMLIonLoadingElement;

  constructor(
    private loadingCtrl: LoadingController,
    private alertCtrl: AlertController,
    private platform: Platform,
    private toast: ToastController,
    private storage: Storage
  ) { }

  /**
   * Muestra loading
   * @param message Mensaje del loading (opcional)
   */
  async showLoading(message?: string, duration?: number) {
    this.loading = await this.loadingCtrl.create({
      message: message ? message : '<img src="assets/imgs/logo-ball-only.png" class="logo-ball-loading">',
      duration: duration ? duration : null
    });
    return this.loading.present();
  }

  /**
   * Quita el loading cargado (arreglado)
   */
  public dismissLoading() {
    if (this.loading) {
      return this.loading.dismiss().then(() => { return true; })
    }
    return Promise.resolve(true);
  }

  /**
   * Devuelve el sistema operativo del dispositivo
   */
  public getPlatform() {
    return this.platform.is('ios') ? 'ios' : 'android';
  }

  /**
   * Devuelve el nombre del archivo pasado (incluida la extensión)
   * @param path Ruta del archivo
   */
  public getFileName(path: string) {
    return path.split('/').pop();
  }

  /**
   * Devuelve la extensión del archivo pasado
   * @param path Ruta del archivo
   */
  public getFileExtension(path: string) {
    return path.split('.').pop().toLowerCase();
  }

  /**
   * Muestra un alert genérico para notificar algo (un error, éxito, etc)
   * @param title Título del alert
   * @param message Mensaje del alert
   */
  public async showAlert(title: string, message: string, backdropDismiss = true) {
    let alert = await this.alertCtrl.create({
      header: title,
      message: message,
      buttons: ['OK'],
      backdropDismiss: backdropDismiss
    });

    alert.present();
  }

  /**
   * Muestra un toast genérico para notificar algo (un error, éxito, etc)
   * @param message Mensaje del toast
   */
  public async showToast(message: string, duration = 5000) {
    const toast = await this.toast.create({
      message: message,
      duration: duration,
      buttons: ['OK']
    });
    toast.present();
  }

  public capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Devuelve el texto con los datos del QR escaneado, o si hay error durante el proceso
   * @returns Texto con los datos del QR escaneado
   */
  public scanQr(): Promise<string> {
    return new Promise((resolve, reject) => {
      QRScanner.prepare((err, status) => {
        if (err) {
          QRScanner.destroy();
          reject({ err, status });
        }
        if (status.authorized) {
          this.toggleAppTransparentElements();
          QRScanner.scan((err, text) => {
            this.toggleAppTransparentElements(false);
            if (err) {
              reject({ err, status });
            }
            QRScanner.destroy();
            resolve(text);
          });
          QRScanner.show();
        } else if (status.denied) {
          // The video preview will remain black, and scanning is disabled. We can
          // try to ask the user to change their mind, but we'll have to send them
          // to their device settings with `QRScanner.openSettings()`.
          QRScanner.destroy();
          reject({ err, status });
        } else {
          QRScanner.destroy();
          reject({ err, status });
        }
      });
    });
  }

  /**
   * Transparenta u opaca los elementos HTML para que se muestre u oculte correctamente el escáner QR
   * @param shouldTransparent Si debería transparentarse los elementos HTML o no
   */
  private toggleAppTransparentElements(shouldTransparent = true) {
    const body = document.getElementsByTagName('body')[0] as HTMLElement;
    body.style.background = shouldTransparent ? 'transparent' : '';
    body.style.display = shouldTransparent ? 'none' : '';

    const appRoot = document.getElementsByTagName('app-root')[0] as HTMLElement;
    appRoot.style.background = shouldTransparent ? 'transparent' : '';
    appRoot.style.display = shouldTransparent ? 'none' : '';

    const ionApp = document.getElementsByTagName('ion-app')[0] as HTMLElement;
    ionApp.style.background = shouldTransparent ? 'transparent' : '';
    ionApp.style.display = shouldTransparent ? 'none' : '';

    const ionContent = document.getElementsByTagName('ion-content')[0] as HTMLElement;
    ionContent.style.background = shouldTransparent ? 'transparent' : '';
    ionContent.style.display = shouldTransparent ? 'none' : '';
  }

  isNumeric(value) {
    return /^\d+$/.test(value);
  }
}