import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'cover-page', pathMatch: 'full' },
  { path: 'cover-page', loadChildren: () => import('./pages/cover-page/cover-page.module').then(m => m.CoverPagePageModule) },
  { path: 'logout', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule) },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule) },
  { path: 'home', loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: 'details', loadChildren: () => import('./pages/details/details.module').then(m => m.DetailsPageModule) },
  { path: 'profile', loadChildren: () => import('./pages/profile/profile.module').then(m => m.ProfilePageModule) },
  { path: 'forgot-password', loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule) },
  { path: 'chats', loadChildren: () => import('./pages/chats/chats.module').then(m => m.ChatsPageModule) },
  { path: 'interior-chat/:id_chat/:nombre_chat/:ultimo_mensaje/:urlImagen', loadChildren: () => import('./pages/interior-chat/interior-chat.module').then(m => m.InteriorChatPageModule) },
  { path: 'tabs', loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'profile-edit', loadChildren: './pages/profile-edit/profile-edit.module#ProfileEditPageModule' },
  { path: 'settings', loadChildren: './pages/settings/settings.module#SettingsPageModule' },
  { path: 'privateprofile-edit', loadChildren: './pages/privateprofile-edit/privateprofile-edit.module#PrivateprofileEditPageModule' },
  { path: 'search-map', loadChildren: './pages/search-map/search-map.module#SearchMapPageModule' },
  { path: 'tabs', loadChildren: () => import('./pages/tabs/tabs.module').then(m => m.TabsPageModule) },
  { path: 'scan-qr', loadChildren: './pages/scan-qr/scan-qr.module#ScanQrPageModule' },
  { path: 'free-trial', loadChildren: './pages/free-trial/free-trial.module#FreeTrialPageModule' },
  { path: 'search-list', loadChildren: './pages/search-list/search-list.module#SearchListPageModule' },
  { path: 'record-videodetail', loadChildren: './pages/record-videodetail/record-videodetail.module#RecordVideodetailPageModule' },
  { path: 'upload-post', loadChildren: './pages/upload-post/upload-post.module#UploadPostPageModule' },
  { path: 'record', loadChildren: './pages/record/record.module#RecordPageModule' },
  { path: 'record-data', loadChildren: './pages/record-data/record-data.module#RecordDataPageModule' },
  { path: 'start-record', loadChildren: './pages/start-record/start-record.module#StartRecordPageModule' },
  { path: 'videos-gallery', loadChildren: './pages/videos-gallery/videos-gallery.module#VideosGalleryPageModule' },
  { path: 'my-posts', loadChildren: './pages/my-posts/my-posts.module#MyPostsPageModule' },
  { path: 'post-detail', loadChildren: './pages/post-detail/post-detail.module#PostDetailPageModule' },
  { path: 'edit-post', loadChildren: './pages/edit-post/edit-post.module#EditPostPageModule' },
  { path: 'view-profile', loadChildren: './pages/view-profile/view-profile.module#ViewProfilePageModule' },
  { path: 'payment', loadChildren: './pages/payment/payment.module#PaymentPageModule' },
  { path: 'blocked-users' , loadChildren: './pages/blocked-users/blocked-users.module#BlockedUsersPageModule' },
  { path: 'contact' , loadChildren: './pages/contact/contact.module#ContactPageModule' },
  { path: 'notifications' , loadChildren: './pages/notifications/notifications.module#NotificationsPageModule' },
  { path: 'followers-ing' , loadChildren: './pages/followers-ing/followers-ing.module#FollowersIngPageModule' },
  { path: 'privacy-policy', loadChildren: './pages/privacy-policy/privacy-policy.module#PrivacyPolicyPageModule' },
  { path: 'play-user-video', loadChildren: './pages/play-user-video/play-user-video.module#PlayUserVideoPageModule' },
  { path: 'report-user', loadChildren: './pages/report-user/report-user.module#ReportUserPageModule' },  { path: 'terms-and-conditions', loadChildren: './pages/terms-and-conditions/terms-and-conditions.module#TermsAndConditionsPageModule' },
  { path: 'saved-credit-cards', loadChildren: './pages/saved-credit-cards/saved-credit-cards.module#SavedCreditCardsPageModule' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
