import { Component, OnInit, ViewChild } from '@angular/core';
import { Platform, Events, NavController, AlertController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { ApiService } from './services/api.service';
import { User } from './models/User';
import { Push, PushObject, PushOptions } from '@ionic-native/push/ngx';
import { environment } from "../environments/environment";
import { Stripe } from '@ionic-native/stripe/ngx';
import { AuthenticationService } from './services/authentication.service';
import { Storage } from '@ionic/storage';
import { HttpErrorResponse } from '@angular/common/http';
import { UtilitiesService } from './services/utilities.service';
import { Device } from '@ionic-native/device/ngx';
import { FCMNotification } from './models/FCMNotification';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { Event, NavigationStart, Router } from '@angular/router';
import { CdTimerComponent, TimeInterface } from 'angular-cd-timer';

declare var cordova: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent implements OnInit {

  @ViewChild('cdTimer', { static: false }) cdTimer: CdTimerComponent;

  user: User;
  public isLoading: boolean = true;
  public appPages = [
    {
      title: 'Inicio',
      url: '/home',
      icon: 'home'
    },
    {
      title: 'Perfil',
      url: '/profile',
      icon: 'person'
    },
    {
      title: 'Chats',
      url: '/chats',
      icon: 'chatboxes'
    }
  ];
  intervalUnreadNotifications;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private apiService: ApiService,
    private push: Push,
    private events: Events,
    private stripe: Stripe,
    private auth: AuthenticationService,
    private navCtrl: NavController,
    private storage: Storage,
    private utilities: UtilitiesService,
    private device: Device,
    private screenOrientation: ScreenOrientation,
    private router: Router,
    private alertCtrl: AlertController
  ) {
  }

  /**
   * Nos suscribimos a los cambios del perfil
   */
  public async ngOnInit() {
    this.auth.authenticationState.subscribe(async (token) => {
      if (token != 'logout' && token != '') {
        this.pushNotifications();
        //this.prepararStripe();
        this.apiService.setTokenToHeaders(token);
        this.navCtrl.navigateRoot('tabs').then(() => {
          this.isLoading = false;
        });
        this.intervalUnreadNotifications = setInterval(async () => {
          await this.apiService.updateHasUnreadNotifications();
        }, 5000);

        let responseCurrentUserMatch = await this.apiService.getEntity('user-matches/current-match').toPromise();
        this.apiService.currentUserMatch = responseCurrentUserMatch.user_match;
        if (this.apiService.currentUserMatch) {
          this.apiService.currentUserMatchStartTime = this.apiService.getCurrentUserMatchStartTime();
        }

      } else if (token == 'logout') {
        this.apiService.removeTokenToHeaders();
        this.navCtrl.navigateRoot('cover-page').then(() => {
          this.isLoading = false;
        });
        if (this.intervalUnreadNotifications) {
          clearInterval(this.intervalUnreadNotifications);
        }
      } else {
        this.isLoading = false;
        console.log("primera vez");
        if (this.intervalUnreadNotifications) {
          clearInterval(this.intervalUnreadNotifications);
        }
      }

      // IMPORTANTE: para comprobar si la app está o no suspendida, debe ponerse el dominio en la propiedad "domainUrl" del archivo "src/environments/environment.ts"
      // this.checkIfAppIsSuspended();
    });

    try {
      let response = await this.apiService.getEntity('platform-disabled').toPromise();
      if (response.platform_disabled) {
        let alert = await this.alertCtrl.create({
          header: 'Mantenimiento',
          message: 'Estamos realizando labores de mantenimiento, estaremos muy pronto de vuelta',
          backdropDismiss: false,
          buttons: ['OK']
        });
        alert.present();
      }
    }
    catch (err) {
      console.log('err platform disabled');
      console.log(err);
    }

    if (this.platform.is('cordova')) {
      this.platform.ready().then(() => {
        this.statusBar.styleBlackOpaque();
        this.splashScreen.hide();
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        this.listenRouteChangesForOrientation();
        this.apiService.addEntity('app-downloads', {
          model: this.device.model,
          platform: this.device.platform,
          uuid: this.device.uuid,
          version: this.device.version,
          manufacturer: this.device.manufacturer
        }).toPromise().then(response => {
          // console.log(response);
        }).catch(errorAppDownload => {
          console.log({ errorAppDownload });
        });
      });
    }

    this.apiService.userChanges.subscribe((user: User) => {
      this.user = user;
    });
  }

  listenRouteChangesForOrientation() {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (event.url == '/tabs/home' || event.url == '/tabs') {
          this.screenOrientation.unlock();
        }
        else {
          this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
        }
      }
    });
  }

  /**
   * Comprueba si el dominio de la aplicación está suspendido. Si lo está, muestra un mensaje de aviso al usuario
   */
  public checkIfAppIsSuspended() {
    this.apiService.checkAppDomain().subscribe(async (response) => {
      // no hacemos nada, ya que el dominio de la aplicación estaría activado
    },
      async (errorResponse: HttpErrorResponse) => {
        if (errorResponse.status == 0 || errorResponse.status == 403) {
          this.utilities.showAlert('Esta app no ha sido renovada', 'Si usted es el propietario, por favor hable con nosotros en el 956 105 343 para renovar el servicio o contacte con facturacion@xerintel.es', false);
        }
      })
  }

  public async pushNotifications() {

    await cordova.plugins.firebase.messaging.requestPermission({ forceShow: false });

    let token;

    try {
      token = await cordova.plugins.firebase.messaging.getToken();
    }
    catch (err) {
      console.log('err obtener token firebase');
      console.log(err);
      return;
    }

    console.log('token = ');
    console.log(token);

    if (token) {
      this.apiService.guardarTokenDeRegistro(token).subscribe((response) => {
        console.log(response);
      }, (error) => {
        console.log(error);
      });
    }

    cordova.plugins.firebase.messaging.onMessage((payload: FCMNotification) => {

      console.log("New foreground FCM message");
      console.log(payload);

      this.utilities.showAlert(payload.title, payload.message);
    });
  }

  /**
   * Preparamos stripe con su configuración
   */
  public prepararStripe(): void {
    this.stripe.setPublishableKey(environment.stripePublishableKey);
  }

  checkTimer(event: TimeInterface) {
    // console.log('checkTimer');
    // console.log(event);

    let shouldResetCurrentMatchData = false;

    if (this.cdTimer) {
      if (this.apiService.currentUserMatch) {
        if (this.apiService.currentUserMatch.duration == '1h') {
          if (event.hours == 1 && event.minutes >= 0 && event.seconds >= 1) {
            shouldResetCurrentMatchData = true;
          }
        }
        else if (this.apiService.currentUserMatch.duration == '1h 30 min') {
          if (event.hours == 1 && event.minutes >= 30 && event.seconds >= 1) {
            shouldResetCurrentMatchData = true;
          }
        }
      }
    }

    if (shouldResetCurrentMatchData) {
      this.cdTimer.reset();
      this.apiService.currentUserMatch = null;
      this.apiService.currentUserMatchStartTime = 0;
    }
  }
}
