/**
 * Variables globales de la aplicación en local
 */


export const environment = {
  production: false,

  // URL del dominio asociado a la aplicación, para comprobar si está o no suspendido
  domainUrl: 'https://padelrecord.es',

  // URL del endpoint de la api de pruebas
  // apiUrl: 'http://192.168.0.160:8000/api/auth/',
  // apiUrl: 'http://192.168.0.75:8000/api/auth/',
  apiUrl: 'https://padelrecord.es/api/auth/',

  // Sender id para las notificaciones push
  senderID: '817833546241',

  /*
    -----------------------------------------------------------------------------------------------
    |                                                                                             |
    | COMPROBAR EN EL ARCHIVO .env DEL SERVIDOR LAS CLAVES DE STRIPE (STRIPE_KEY Y STRIPE_SECRET) |
    |                                                                                             |
    -----------------------------------------------------------------------------------------------
  */
  // stripePublishableKey: 'pk_test_51L7vXSEv07mCFzsge67PDGGRjztMqU3J5cy5exdV7arWjXjMwDG19lWUG8gKLWKKW61vYl0kPdrRmFRGGIjb7NSk00vcCn9k2q',
  stripePublishableKey: 'pk_live_51L7vXSEv07mCFzsgKXpAzTGdHBNcLUtQaExDyqZVcCrNzcsZu0GJ43aEv8PEIwC7XdPRUpw0uAGzfy7vWNUiJAtE001tVaP0Ks',
};
